import React from 'react'
import { PageProps } from 'gatsby'
import { useApi } from '~/siteApi'
import {
  Head,
  HomeHero,
  HomeAbout,
  EnterpriseSlider,
  Testimonials,
  Footer,
} from '~/components'

interface Context {
  showcases: Array<{
    id: number
    location: string
    title: string
    subtitle: string
    cta: string
    url: string
    external: true
    image: string
    mobile: string
  }>
  numbers: Array<{
    id: number
    name: string
    description: string
  }>
  enterprises: Array<{
    id: number
    name: string
    slug: string
    items: Array<{
      id: number
      name: string
      slug: string
      room: string
      neighborhood: string
      city: string
      state: string
      tag: string
      imageFeature: string
      imageSideFirst: string
      imageSideSecond: string
      imageSideThird: string
    }>
  }>
  testimonials: Array<{
    id: number
    name: string
    description: string
    image: string
  }>
}

const Home = ({ location, pageContext }: PageProps) => {
  const { showcases, enterprises, testimonials }: Context = useApi(
    pageContext,
    'page-home'
  )

  return (
    <>
      <Head location={location} />
      <HomeHero showcases={showcases} />
      <HomeAbout />
      {/* <Numbers /> */}
      {enterprises.map(({ id, name, items }) => (
        <EnterpriseSlider
          key={id}
          title={name}
          enterprises={items}
          slider={!name.toLowerCase().includes('lançamento')}
        />
      ))}
      <Testimonials items={testimonials} />
      <Footer />
    </>
  )
}

export default Home
